import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import {
  ContentSection,
  Hero,
  ISIMainContent,
  Layout,
  SEO,
} from '~/components';
import { Frontmatter } from '~/config';
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Important Safety Information',
  order: 103,
  screenShoot: true,
};

/**
 * ISI Page
 */
const Page: FC<PageProps> = (props) => {
  return (
    <Layout noTray>
      <SEO path={props.location?.pathname} />

      <Hero textLeft halfHeight titleStyle={tw`mb-4 mt-0 md:(mt-2)`}>
        Important Safety Information
      </Hero>
      <ContentSection>
        <ISIMainContent />
      </ContentSection>
    </Layout>
  );
};

export default Page;
